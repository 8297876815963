export class Point {
    x = 0;
    y = 0;
}

// TODO other stuff

export class Bounds {
    x = 0;
    y = 0;
    width = 0;
    height = 0;
}
